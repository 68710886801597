
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$recf-primary--palette: (
  50: #e6e9ee,
  100: #bec8d7,
  200: #96a5bb,
  300: #6f839f,
  400: #50698d,
  500: #2f507d, // Primary color
  600: #294975,
  700: #21406a,
  800: #1b365d,
  900: #142645,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$rec-foundation-events-primary: mat.define-palette(mat.$indigo-palette);
$rec-foundation-events-accent: mat.define-palette($recf-primary--palette);

// The warn palette is optional (defaults to red).
$rec-foundation-events-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rec-foundation-events-theme: mat.define-light-theme((
  color: (
    primary: $rec-foundation-events-primary,
    accent: $rec-foundation-events-accent,
    warn: $rec-foundation-events-warn,
  )
));

.dense-form-field {
    @include mat.form-field-density(-5); // Adjust the density value as needed
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rec-foundation-events-theme);


mat-tab-group > mat-tab-header {
  border-bottom: 1px solid #c9c9c9 !important;
  background-color: #f1f3f6;
}