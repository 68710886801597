@import '@mt-ng2/styles/styles.less';
@import "styles/_fonts";
@import (reference) 'styles/_global-variables';
@import 'ng-select';

mt-multiselect span button {
    white-space: normal!important;
}

.inline, .inline > * {
    display: inline-block;
}

.text-centered {
    text-align: center;
}

.text-right {
    text-align: right;
}

.centered {
    margin: 0 auto;
}

.no-border {
    border: none !important;
}

.pad-left-1 {
    padding-left: 1em;
}

.pad-left-2-half {
    padding-left: 2.5em;
}

.pad-right-1 {
    padding-right: 1em !important;
}

@media (max-width: 768px) {
    .btn.btn-fab-md {
        z-index: 2 !important;
    }

    .btn.btn-fab-md:hover {
        background-color: #3264ac !important;
    }

    span.ng2-imgcrop canvas {
        width: 95% !important;
        height: 100% !important;
    }
}

.flex-spread-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > :first-child {
        flex-grow: 1;
    }
    > :nth-child(2) {
        flex-grow: 1;
        text-align: right;
        max-width: 50%;
    }
}

.pad-container > * {
    margin: .25em;
}

.pad-top-half {
    padding-top: 0.5em;
}

.can-edit {
    cursor: pointer;
}

.edit-icon {
    display: none;
}

.can-edit:hover .edit-icon {
    display: block;
};

.view-user-address-books-modal {
    width: 80%;

    button.swal2-confirm {
        background-color: @primary;
    }

    button:where(.swal2-styled).swal2-confirm:focus {
        box-shadow: none !important;
    }
}

@media (max-width: 30rem) {
    .table-responsive.card-switch td
    {
        width: auto !important;
    }
}

@media screen and (max-width: 767px) {
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: normal !important;
        min-height: 37px !important;
    }
}

// Data saved via mt-common-notes WYSIWYG editor is wrapped in a p tag which doesn't show up in the editor.
// This removes the margin from the p tag to maintain the same look as the editor.
// See task https://milesit.striven.com/Tasks/TaskInfo.aspx?TaskID=4988684
.mt-common-notes--wrapper {
    p {
        margin: 0 !important;
    }
}

@media (max-width: 768px) {
    #event-volunteers-list--wrapper {
        td {
            word-break: break-all;
        }

        .event-volunteers-list--dynamic-cell-column {
            padding-left: 0;
        }
    }
}

.cyan-bg {
    background-color: #16BECF;
    color: white;
}

.recf-tab-label {
    font-weight: 500!important;
    font-size: 1.3em!important;
}

.recf-tab-content {
    padding-top: 2em;
    &-left {
        @media screen and (min-width: 992px) {
            padding-left: 0px;
        }
    }
    &-right {
        @media screen and (min-width: 992px) {
            padding-right: 0px;
        }
    }
  }

.inactive-row {
    opacity: 0.7;
    td {
        background-color: rgb(228, 228, 228) !important;
    }
}

.text-wrap {
    white-space: normal;
}
